<template>
<div>
  <defaultFieldTypes
    :fieldAttributes="fieldAttributes"
    :field="field"
    :templateContent="result"
    v-on="$listeners"
    :checkReadOnly="checkReadOnly"
    :value="value"
    :valueLine="valueLine"
    :hideDetails="hideDetails"
  ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: [
    "fieldAttributes",
    "field",
    "result",
    "checkReadOnly",
    "value",
    "valueLine",
    "hideDetails",
  ],
  watch: {
    "valueLine.c_password": function () {
      let value=this.value;
      this.$emit("input", "");
       this.$nextTick(function() {
         this.$emit("input", value);
       })
    },
  },
};
</script>